import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const Chain42161 = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg {...props} viewBox="0 0 296 296">
      <title>Arbitrum logo</title>
      <path
        d="M27.8584 89.4931V204.482C27.8584 211.824 31.7811 218.613 38.1357 222.272L137.72 279.778C144.074 283.437 151.898 283.437 158.253 279.778L257.837 222.272C264.192 218.613 268.114 211.824 268.114 204.482V89.4931C268.114 82.1518 264.192 75.3625 257.837 71.7038L158.253 14.1971C151.898 10.5385 144.074 10.5385 137.72 14.1971L38.1117 71.7038C31.7571 75.3625 27.8584 82.1518 27.8584 89.4931Z"
        fill="#213147"
      />
      <path
        d="M169.712 169.291L155.51 208.237C155.126 209.32 155.126 210.501 155.51 211.584L179.942 278.597L208.2 262.277L174.283 169.291C173.512 167.149 170.48 167.149 169.71 169.291H169.712Z"
        fill="#12AAFF"
      />
      <path
        d="M198.19 103.792C197.419 101.65 194.387 101.65 193.617 103.792L179.414 142.738C179.03 143.821 179.03 145.002 179.414 146.085L219.443 255.802L247.702 239.482L198.187 103.792H198.19Z"
        fill="#12AAFF"
      />
      <path
        d="M147.976 18.5063C148.675 18.5063 149.374 18.6983 149.998 19.037L257.766 81.261C259.016 81.9837 259.787 83.3303 259.787 84.7517V209.176C259.787 210.621 259.016 211.944 257.766 212.666L149.998 274.89C149.395 275.25 148.675 275.421 147.976 275.421C147.278 275.421 146.579 275.229 145.955 274.89L38.1869 212.714C36.9362 211.992 36.1655 210.645 36.1655 209.224V84.7757C36.1655 83.3303 36.9362 82.0077 38.1869 81.285L145.955 19.061C146.582 18.701 147.278 18.5063 147.976 18.5063ZM147.976 0.333008C144.15 0.333008 140.296 1.31968 136.856 3.31701L29.1122 65.517C22.2269 69.4877 17.9922 76.8317 17.9922 84.773V209.197C17.9922 217.141 22.2295 224.482 29.1122 228.453L136.88 290.677C140.323 292.65 144.15 293.661 148 293.661C151.851 293.661 155.68 292.674 159.12 290.677L266.888 228.453C273.774 224.482 278.008 217.138 278.008 209.197V84.773C278.008 76.829 273.771 69.4877 266.888 65.517L159.096 3.31701C155.654 1.31968 151.803 0.333008 147.976 0.333008Z"
        fill="#9DCCED"
      />
      <path
        d="M76.7012 255.947L86.6185 228.795L106.573 245.379L87.9172 262.422L76.7012 255.947Z"
        fill="#213147"
      />
      <path
        d="M138.901 75.8691H111.581C109.536 75.8691 107.706 77.1438 107.008 79.0718L48.4424 239.626L76.701 255.946L141.189 79.1438C141.792 77.5545 140.61 75.8691 138.901 75.8691Z"
        fill="white"
      />
      <path
        d="M186.706 75.8691H159.386C157.341 75.8691 155.511 77.1438 154.813 79.0718L87.9434 262.4L116.202 278.72L188.994 79.1438C189.573 77.5545 188.391 75.8691 186.706 75.8691Z"
        fill="white"
      />
    </EmblemSvg>
  );
};

import { type SVGAttributes } from 'react';
import { EmblemSvg } from '../emblem-svg';

export const GoldYen = (props: SVGAttributes<SVGElement>) => {
  return (
    <EmblemSvg
      {...props}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 512 511.995"
    >
      <path
        fill="#ECCA43"
        fillRule="nonzero"
        d="M256 0c70.685 0 134.689 28.659 181.015 74.984C483.341 121.31 512 185.315 512 256c0 70.684-28.659 134.689-74.985 181.015-46.326 46.322-110.33 74.98-181.015 74.98-70.685 0-134.689-28.658-181.015-74.98C28.659 390.689 0 326.684 0 256c0-70.685 28.659-134.69 74.985-181.016C121.307 28.659 185.311 0 256 0z"
      />
      <path
        fill="#F7E259"
        d="M256 5.007c138.618 0 250.993 112.374 250.993 250.993 0 138.614-112.375 250.988-250.993 250.988S5.007 394.614 5.007 256C5.007 117.381 117.382 5.007 256 5.007z"
      />
      <path
        fill="#F8D548"
        d="M503.753 215.692A252.691 252.691 0 01506.989 256c0 138.614-112.371 250.988-250.989 250.988S5.007 394.614 5.007 256c0-21.857 2.801-43.056 8.051-63.271l246.435 183.476 244.26-160.513z"
      />
      <path
        fill="#D7925B"
        fillRule="nonzero"
        d="M256 58.922c54.414 0 103.688 22.061 139.353 57.725 35.664 35.661 57.725 84.935 57.725 139.349 0 54.417-22.061 103.692-57.725 139.352-35.665 35.664-84.939 57.726-139.353 57.726-54.414 0-103.688-22.062-139.349-57.726-35.664-35.664-57.725-84.938-57.725-139.352s22.061-103.688 57.725-139.349C152.312 80.983 201.586 58.922 256 58.922z"
      />
      <path
        fill="#EDA140"
        d="M256 63.929c106.076 0 192.071 85.994 192.071 192.067 0 106.076-85.995 192.071-192.071 192.071-106.073 0-192.067-85.995-192.067-192.071 0-106.073 85.994-192.067 192.067-192.067z"
      />
      <path
        fill="#C26A34"
        fillRule="nonzero"
        d="M176.108 168.366h55.415l27.955 61.587 27.293-61.587h55.58l-42.368 78.422h36.156v27.41h-51.19v14.974h51.19v27.663h-51.19v33.248h-50.444v-33.248H182.57v-27.663h51.935v-14.974H182.57v-27.41h37.15z"
      />
      <path
        fill="#F3DC6B"
        fillRule="nonzero"
        d="M169.655 161.913h55.414l27.956 61.587 27.293-61.587h55.58l-42.368 78.422h36.155v27.409h-51.19v14.975h51.19v27.663h-51.19v33.247h-50.444v-33.247h-51.935v-27.663h51.935v-14.975h-51.935v-27.409h37.15z"
      />
    </EmblemSvg>
  );
};
